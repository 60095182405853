/**
 * URL REPO
 */

//import by using
//import * as configjs from "../../config";
//export by export var AUTH = BACKEND_HOST+"/wapi/auth";



// var BACKEND_HOST="http://localhost:9122";
var BACKEND_HOST="";  //Production end point
//Endpoints

//AUTH
export var AUTH = BACKEND_HOST+"/wapi/auth/authmail";
export var AUTH_REGISTER = BACKEND_HOST+"/wapi/auth/registermail";
export var AUTH_VERIFY = BACKEND_HOST+"/wapi/auth/verifymail";
export var AUTH_FORGOT = BACKEND_HOST+"/wapi/auth/forgotpass";

//PROFILE
export var USER_PROFILE = BACKEND_HOST+"/wapi/users/getprofile";
export var USER_PROFILE_SAVE = BACKEND_HOST+"/wapi/users/saveprofile";
export var USER_COMPANY_INFO = BACKEND_HOST+"/wapi/users/getCompanyinfo";
export var USER_COMPANY_INFO_SAVE = BACKEND_HOST+"/wapi/users/saveCompanyinfo";


//Query
export var QUERY = BACKEND_HOST+"/wapi/query/query";
export var QUERYC = BACKEND_HOST+"/wapi/query/queryc";

//ADMIN
export var CREATE_COMPANY = BACKEND_HOST+"/wapi/admin/createCompany";

//Category
export var CREATE_CATEGORY = BACKEND_HOST+"/wapi/admin/createCategory";
export var DELETE_CATEGORY = BACKEND_HOST+"/wapi/admin/deleteCategory";

//pricing
export var PRICING_ADD_SHIPPING = BACKEND_HOST+"/wapi/admin/newPricingShippingSpeed";
export var PRICING_DELETE_SHIPPING = BACKEND_HOST+"/wapi/admin/deletePricingShippingSpeed";
export var PRICING_UPDATE_SHIPPING = BACKEND_HOST+"/wapi/admin/updatePricingShippingSpeed";
//---
export var PRICING_ADD_PAYMENT = BACKEND_HOST+"/wapi/admin/newPricingPaymentMethod";
export var PRICING_UPDATE_PAYMENT = BACKEND_HOST+"/wapi/admin/updatePricingPaymentMethod";
export var PRICING_DELETE_PAYMENT = BACKEND_HOST+"/wapi/admin/deletePricingPaymentMethod";
//--
export var PRICING_ADD_WEIGHT = BACKEND_HOST+"/wapi/admin/newPricingWeight";
export var PRICING_UPDATE_WEIGHT = BACKEND_HOST+"/wapi/admin/updatePricingWeight";
export var PRICING_DELETE_WEIGHT = BACKEND_HOST+"/wapi/admin/deletePricingWeight";
//---
export var PRICING_ADD_COURIER = BACKEND_HOST+"/wapi/admin/newPricingCourier";
export var PRICING_UPDATE_COURIER = BACKEND_HOST+"/wapi/admin/updatePricingCourier";
export var PRICING_DELETE_COURIER = BACKEND_HOST+"/wapi/admin/deletePricingCourier";
//---
export var PRICING_ADD_ZONE = BACKEND_HOST+"/wapi/admin/newPricingZone";
export var PRICING_UPDATE_ZONE = BACKEND_HOST+"/wapi/admin/updatePricingZone";
export var PRICING_DELETE_ZONE = BACKEND_HOST+"/wapi/admin/deletePricingZone";


//Order v3
export var ORDER_CREATE = BACKEND_HOST+"/wapi/order/saveOrder";
export var ORDER_EDIT = BACKEND_HOST+"/wapi/order/editOrder";
export var ORDER_CANCEL = BACKEND_HOST+"/wapi/order/cancelOrder";
export var ORDER_DELETE = BACKEND_HOST+"/wapi/order/deleteOrder";
export var ORDER_DOWNLOAD_SAMPLE_EXCEL = BACKEND_HOST+"/wapi/order/downloadOrderExcelTemplate";
export var ORDER_UPLOAD_EXCEL = BACKEND_HOST+"/wapi/order/uploadOrderExcel";
//export var ORDER_UPLOAD_EXCEL = BACKEND_HOST+"/upload";

//couriers
export var AVALAIBLE_COURIER = BACKEND_HOST+"/wapi/order/avaliableCouriers";
export var ASSIGN_COURIER = BACKEND_HOST+"/wapi/order/assignCourier";


//payments
export var RAZORPAY_INIT = BACKEND_HOST+"/wapi/payment/razorpay";
export var RAZORPAY_DONE = BACKEND_HOST+"/wapi/payment/razorPayDone";

//Wallet
export var USER_BALANCE = BACKEND_HOST+"/wapi/users/getBalance";
export var USER_PERFROM_TXN = BACKEND_HOST+"/wapi/admin/walletTxn";

//Generics
export var GENERIC_ORDER_STATUS = BACKEND_HOST+"/wapi/generics/orderstatus";
export var GENERIC_CATEGORY = BACKEND_HOST+"/wapi/generics/categories";
export var GENERIC_PINCODEINFO = BACKEND_HOST+"/wapi/generics/pincodeInfo/";
export var GENERIC_DUMP = BACKEND_HOST+"/wapi/generics/dump";

//cod remmitance
export var COD_MAN_GEN = BACKEND_HOST+"/wapi/codRemmitance/genManCodRem";
export var COD_MAN_APPROVE = BACKEND_HOST+"/wapi/codRemmitance/approveRemmitance";
export var COD_DOWNLOAD = BACKEND_HOST+"/wapi/order/getCodRemittedList";

//warehouse
export var WAREHOUSE_SAVE = BACKEND_HOST+"/wapi/users/warehouseSave";
export var WAREHOUSE_DELETE = BACKEND_HOST+"/wapi/users/warehouseDelete";

//dropLocation
export var DROP_LOCATION_SAVE = BACKEND_HOST+"/wapi/users/dropLocationSave";
export var DROP_LOCATION_DELETE = BACKEND_HOST+"/wapi/users/dropLocationDelete";

//developer
export var DEVELOPER_GENKEY = BACKEND_HOST+"/wapi/users/generateAPIkey";
export var DEVELOPER_GET_KEY = BACKEND_HOST+"/wapi/users/getAPIkey";

//insight
export var USER_INSIGHT = BACKEND_HOST+"/wapi/users/getInsight";

//track parcel
export var TRACK_PARCEL = BACKEND_HOST+"/rest/order/trackParcel";


//zone
export var ZONE_DOWNLOAD = BACKEND_HOST+"/wapi/zones/downloadZoneList";
export var ZONE_UPLOAD = BACKEND_HOST+"/wapi/zones/uploadZoneList";
export var ZONE_GET = BACKEND_HOST+"/wapi/zones/getZones";











