//
export var show_recharge_box=false;
export var enable_metabase=false;

//shipinidiaship
export var fileuploadUrl="https://files.shipindiaship.in/uploads/";
export var filedomain="https://files.shipindiaship.in";
export var apidocurl="http://apidoc.shipindiaship.in/";
export var helpurl="http://apidoc.shipindiaship.in/";
export var logo="https://files.shipindiaship.in/logo.png";

//easyship
// export var fileuploadUrl="https://files.eazyship.co.in/uploads/";
// export var filedomain="https://files.eazyship.co.in";
// export var apidocurl="https://apidoc.eazyship.co.in/";
// export var helpurl="https://www.eazyship.co.in/index.php/contact/";
// export var logo="easzyship.png";
