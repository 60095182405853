<template>
  <q-page class="flex flex-center bg-white">
      <div>
    <img alt="Quasar logo" src="@/assets/turtle.gif" >
      </div>
    <div class="text-h6">
        We are continously working on providing you with better support.
        <br>
        Please contact us <a target="_blank" :href="config.helpurl">From Here</a>

    </div>
  </q-page>
</template>

<style>
</style>

<script>
import * as config from "@/config.js";

export default {
  name: 'Help',
  data() {
    return {
      apiKey: "",
      config:config

    };
  },
}
</script>
