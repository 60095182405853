<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Shipments</div>

    <div class="row items-start" style="width:90%;margin:auto">
      <!-- sencond inline bar -->
      <div class="row q-ma-sm">
        <q-btn color="primary" label="add new order" class="q-mr-xs q-mt-xs" @click="$router.push('/neworders')" />
        <!-- <q-btn color="primary" label="download excel template" class="q-mr-xs q-mt-xs" @click="api_downloadTemplateFile()" />
        <q-btn color="primary" label="upload excel file" class="q-mr-xs q-mt-xs" @click="api_uploadFile()" />-->
        <q-btn color="primary" :label="'bulk print label = '+selectedrders.length" class="q-mr-xs q-mt-xs" @click="bulkPrintLabel()" />
        <q-btn-dropdown style="height:35px;margin:auto;margin-top:4px" dense color="primary" label="Bulk Add Orders">
          <q-list>
            <q-item clickable v-close-popup @click="api_downloadSampleExcel()">
              <q-item-section>
                <q-item-label>Download Sample Excel</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="selectuploadExcel()">
              <q-item-section>
                <q-item-label>Upload Excel</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
    <div class="q-pa-md row items-start" style="width:90%;margin:auto">
      <q-input filled class="q-mr-xs q-mt-xs" v-model="search.startDate" label="Start date" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.startDate !== ''" name="close" @click.stop="search.startDate = ''" class="cursor-pointer" />
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy cover ref="qDateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="search.startDate">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="OK" color="primary" flat v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-input filled class="q-mr-xs q-mt-xs" v-model="search.endDate" label="End date" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.endDate !== ''" name="close" @click.stop="search.endDate = ''" class="cursor-pointer" />

          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy cover ref="qendDateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="search.endDate" @input="() => $refs.qendDateProxy.hide()">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="OK" color="primary" flat v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-select stack-label class="q-mr-xs q-mt-xs" filled v-model="search.orderStatus" option-label="order_status" option-value="order_status" :options="orderStatusList" emit-value label="status" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.orderStatus !== ''" name="close" @click.stop="search.orderStatus = ''" class="cursor-pointer" />
        </template>
      </q-select>

      <q-select stack-label class="q-mr-xs q-mt-xs" filled v-model="search.payment_method" option-label="paymentmethod" option-value="code" :options="paymentMethod" emit-value label="payment method" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.payment_method !== ''" name="close" @click.stop="search.payment_method = ''" class="cursor-pointer" />
        </template>
      </q-select>

      <q-btn color="primary" icon="search" label="search" class="q-mr-xs q-mt-xs" @click="btn_serarch()" />
      <q-btn color="primary" icon="fas fa-download" label="download" class="q-mr-xs q-mt-xs" @click="util_downloadFile()" />
    </div>

    <!-- main card -->
    <div class="card q-pa-md scroll-x" style="width:90%;margin:auto">
      <div class="row q-ma-sm">
        <q-input filled class="q-mr-xs q-mt-xs" v-model="search.tracking" label="search tracking number or order number" dense style="width: 300px;">
          <template v-slot:append>
            <q-icon size="xs" v-if="search.tracking !== ''" name="close" @click.stop="search.tracking = ''" class="cursor-pointer" />
          </template>
        </q-input>
        <q-btn color="primary" label="Search" class="q-mr-xs q-mt-xs" @click="btn_serarch()" />

        <!--
              <q-input filled class="q-mr-xs q-mt-xs" v-model="search.mobilenumber" label="search customer mobile number" dense style="width: 300px;">
                <template v-slot:append>
                  <q-icon size="xs" v-if="search.mobilenumber !== ''" name="closexm" @click.stop="search.mobilenumber = ''" class="cursor-pointer" />
                </template>
        </q-input>-->
      </div>
      <!-- <q-table :data="data" :columns="columns" row-key="name" :rows-per-page-options="[1]">
          <template v-slot:body-cell-action="props">
            <q-td :props="props">
              <q-btn size="xs" round color="primary" icon="fas fa-eye" class="q-mr-xs" @click="tableViewButton(props)" />
              <q-btn size="xs" round color="primary" icon="edit" class="q-mr-xs" @click="tableEditButton(props)" />
              <q-btn size="xs" round color="secondary" icon="delete" @click="tableDeleteButton(props)" />
            </q-td>
          </template>
      </q-table>-->

      <!-- Table -->
      <table class="styled-table">
        <thead>
          <tr>
            <th></th>
            <th>Created At</th>
            <th>Order Number</th>
            <th>Tracking Number</th>
            <th>Customer Name</th>
            <th>Payment Method</th>
            <th>COD Value</th>
            <th>COD Remmitance Status</th>
            <th>COD Remmitance tnxid</th>
            <th>Courier name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              <q-checkbox v-model="selectedrders" :val="item" color="orange" />
            </td>
            <td>{{new Date(item.created_at).toLocaleString()}}</td>
            <td>{{item.order_number}}</td>
            <td>{{item.tracking_number || ""}}</td>
            <td>{{(item.delivery != undefined ? item.delivery.name : "")}}</td>
            <td>{{item.payment_method || ""}}</td>
            <td>{{item.cod_amount || ""}}</td>
            <td>{{item.cod_remittance_status || ""}}</td>
            <td>{{item.cod_remittance_txnid || ""}}</td>
            <td>{{item.courier_name || ""}}</td>
            <td>{{item.order_status || ""}}</td>
            <td>
              <q-btn-dropdown size="sm" color="primary" label="Actions">
                <q-list dense>
                  <q-item clickable v-close-popup @click="bulkPrintLabel(item)">
                    <q-item-section avatar>
                      <q-avatar icon="print" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Print Label</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="viewOrder(item)">
                    <q-item-section avatar>
                      <q-avatar icon="visibility" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>View Order Details</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="item.order_status != 'draft'" clickable v-close-popup @click="api_cancelOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="delete_forever" color="red" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Cancel Order</q-item-label>
                    </q-item-section>
                  </q-item>
                   <q-item v-if="item.order_status == 'draft'" clickable v-close-popup @click="api_deleteOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="delete_forever" color="red" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Delete Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="editOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="edit" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Edit Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="editOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="local_shipping" color="green" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Ship Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="viewStatusHistory(item)">
                    <q-item-section avatar>
                      <q-avatar icon="format_list_numbered" color="green" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Status history</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="10" class="text-center">
              <q-btn-group rounded>
                <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded icon="chevron_left" label="Previous" />
                <q-btn no-caps v-if="!lastpage" @click="nextPage()" color="green" rounded icon-right="navigate_next" label="Next" />
              </q-btn-group>
            </td>
          </tr>
        </tfoot>
      </table>
      <!-- Table End -->
    </div>

    <orderViewDialig v-model="viewdialog" :order="viewdialogData"></orderViewDialig>

    <q-dialog v-model="orderStatusHistoryDialog">
      <orderhistory :order="orderStatusHistoryDialogData"></orderhistory>
    </q-dialog>

    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
require("./tableStyle.css");

import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";
import orderViewDialig from "./OrderViewDialog.vue";
import orderhistory from "./OrderStatusHistoryDialog.vue";



export default {
  mixins: [globalmix],
  name: "NewOrder",
  components: {
    loader,
    orderViewDialig,
    orderhistory,
  },
  setup(){

  },
  mounted() {
    this.pageno = 0;
    //if mounted and ther is order at the query
    if (this.$route.query.odr != undefined) {
      if (this.$route.query.odr != "") {
        this.search.tracking = this.$route.query.odr;
      }
    }
    this.api_loadOrderStatus();
    this.api_loadorders();
  },
  data() {
    return {
      orderStatusHistoryDialog: false,
      orderStatusHistoryDialogData: {},
      viewdialog: false,
      viewdialogData: {},
      lastpage: false,
      pageno: 0,
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["cod", "prepaid"],
      search: {
        startDate: "",
        endDate: "",
        tracking: "",
        mobilenumber: "",
        orderStatus: "",
        payment_method: "",
      },
      selectedrders: [],
      columns: [
        {
          name: "created_at",
          align: "left",
          label: "Created at",
          field: (row) => row.created_at,
          format: (val) => `${new Date(val).toLocaleString()}`,
          // sortable: true,
        },
        {
          name: "orderid",
          align: "center",
          label: "Order id",
          field: (row) => row.description,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "trackingid",
          align: "center",
          label: "Tracking id",
          field: (row) => row.txn_type,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "customername",
          align: "center",
          label: "Customer Name",
          field: (row) => row.txn_amount,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "paymentmode",
          align: "center",
          label: "Payment Mode",
          field: (row) => row.balance,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "deliverypincode",
          align: "center",
          label: "Delivery Pincode",
          field: (row) => row.balance,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "status",
          align: "center",
          label: "Status",
          field: (row) => row.balance,
          format: (val) => `${val}`,
          // sortable: true,
        },
        {
          name: "action",
          align: "center",
          label: "Action",
          field: (row) => row.balance,
          format: (val) => `${val}`,
          // sortable: true,
        },
      ],
      data: [],
      orderStatusList: [],
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadorders();
    },
    previousPage() {
      if (this.pageno > 0) {
        this.pageno--;
        this.api_loadorders();
      }
    },
    api_loadorders() {
      var queryParam = {
        orders_v3: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
        },
      };
      //set the filters for the tracking number
      if (this.search.tracking != "" && this.search.tracking != undefined) {
        queryParam.orders_v3._filtersor = {};
        queryParam.orders_v3._filtersor.order_number = `LIKE '%${this.search.tracking}%'`;
        queryParam.orders_v3._filtersor.tracking_number = `LIKE '%${this.search.tracking}%'`;
      }

      //set the filters
      if (this.search.orderStatus != "") {
        queryParam.orders_v3._filters.order_status =
          "='" + this.search.orderStatus + "'";
      }

      if (this.search.payment_method != "") {
        queryParam.orders_v3._filters.payment_method =
          "='" + this.search.payment_method + "'";
      }

      //time filter
      if (this.search.startDate != "" && this.search.endDate != "") {
        queryParam.orders_v3._filters.created_at =
          "> " + `'${this.search.startDate} 00:00'`;
        queryParam.orders_v3._filters["#created_at"] =
          "< " + `'${this.search.endDate} 23:59'`;
      }

      this.loading = true;
      axios
        .post(ULRrepo.QUERYC, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    bulkPrintLabel(prop) {
      window.toprint = [];
      if (prop != undefined) {
        window.toprint = [prop];
      } else {
        window.toprint = this.selectedrders;
      }
      //this.goToPageWithParams("shiplabelprinter",{lables:JSON.stringify(this.selectedrders)})
      this.$router.push("shiplabelprinter");
    },
    goToPageWithParams(routeName, paramsObj) {
      console.log(paramsObj);
      this.$router.push({
        name: routeName,
        params: paramsObj,
      });
    },
    api_loadOrderStatus() {
      this.loading = true;
      axios
        .post(ULRrepo.GENERIC_ORDER_STATUS, {})
        .then((response) => {
          if (response.status == 200) {
            this.orderStatusList = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
          //this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    btn_serarch() {
      this.pageno = 0;
      this.api_loadorders();
    },
    editOrder(number) {
      this.$router.push({
        name: "neworders",
        query: { odr: number },
      });
    },
    viewOrder(orderdata) {
      this.viewdialogData = orderdata;
      this.viewdialog = true;
    },
    viewStatusHistory(orderdata) {
      this.orderStatusHistoryDialogData = orderdata;
      this.orderStatusHistoryDialog = true;
    },
    api_cancelOrder(orderNumber) {
      this.loading = true;
      axios
        .post(ULRrepo.ORDER_CANCEL + "?order=" + orderNumber, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
     api_deleteOrder(orderNumber) {
      this.loading = true;
      axios
        .post(ULRrepo.ORDER_DELETE + "?order=" + orderNumber, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
              this.api_loadorders();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_downloadSampleExcel() {
      var urlx = ULRrepo.ORDER_DOWNLOAD_SAMPLE_EXCEL;
      // window.open(url);
      this.loading = true;
      axios({
        url: urlx, //your url
        method: "POST",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sampleExcel.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },
    selectuploadExcel(){
       var input = document.createElement("input");
        input.type = "file";
        input.name = "uploaded_file";
        input.onchange = e => {
        var file = e.target.files[0];
        console.log("selectFile", file);
        var fileUrl = window.URL.createObjectURL(file);
        console.log("fileUrl", fileUrl);
        //upload the file
        this.api_uploadExcel(file);
      };
      input.click();
    },
    api_uploadExcel(uploadedFile) {

      this.loading = true;
       var formData = new FormData();
      formData.append("uploaded_file", uploadedFile);
      axios
        .post(ULRrepo.ORDER_UPLOAD_EXCEL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },

    util_downloadFile(){
         
      var query = "select ca.company_name, o3.created_at,o3.updated_at,o3.order_number,o3.tracking_number,o3.weight_unit,o3.shipment_length,o3.shipment_breadth,o3.shipment_height,o3.real_weight,o3.volumetric_weight,o3.payment_method,o3.shipping_speed,o3.cod_amount,o3.order_value,o3.courier_name,o3.order_status,o3.shipping_charge ,da.name delivery_name,da.address_line1 delivery_address_line1,da.address_line2 delivery_address_line2,da.landmark delivery_landmark,da.state delivery_state,da.city delivery_city,da.pincode delivery_pincode,da.mobile delivery_mobile ,pa.name pickup_name,pa.address_line1 pickup_address_line1,pa.address_line2 pickup_address_line2,pa.landmark pickup_landmark,pa.state pickup_state,pa.city pickup_city,pa.pincode pickup_pincode,pa.mobile pickup_mobile from orders_v3 o3 JOIN order_v3_addresses da ON da.id = o3.delivery_address_id JOIN order_v3_addresses pa ON pa.id = o3.pickup_address_id JOIN company ca ON ca.id = o3.company_id";
      //add compnay id
      query+=" where o3.company_id = "+localStorage.getItem('comp_id');

      //set the filters
      if (this.search.orderStatus != "") {
        query+=" AND o3.order_status='"+this.search.orderStatus+"'";
      }

      if (this.search.payment_method != "") {
         query+=" AND o3.payment_method='"+this.search.payment_method+"'";
      }

      //time filter
      if (this.search.startDate != "" && this.search.endDate != "") {

          query+=" AND o3.created_at >'"+this.search.startDate+" 00:00'";
          query+=" AND o3.created_at <'"+this.search.endDate+" 00:00'";
      }

      var urlx = ULRrepo.GENERIC_DUMP;
      // window.open(url);
      this.loading = true;
      axios({
        url: urlx, //your url
        method: "POST",
        responseType: "blob", // important
        data: {"query":query}
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dump.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });

    }

  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.doc-card-title {
  margin-left: -24px;
  padding: 2px 10px 2px 24px;
  background: #e0e0e0;
  color: #616161;
  position: relative;
  border-radius: 3px 5px 5px 0;
}

.doc-card-title:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-top-color: #bebebe;
  border-width: 9px 0 0 11px;
}
</style>