<template>
  <q-page class="col">
    <iframe :src="iframeUrl" frameborder="0" width="100%" height="600" allowtransparency></iframe>
  </q-page>
</template>
<script>

import * as config from "@/config.js";


export default {

  data() {
    return {
      iframeUrl: ""
    }
  },
  mounted() {
    var jwt = require("jsonwebtoken");

    var METABASE_SITE_URL = "https://metabase.ionoxsoftware.in";
    var METABASE_SECRET_KEY = "a11c730cd83d28f5c961fe45197256d8ffc0a3c90c99f60981a64d65b09f01fc";

    var payload = {
      resource: { dashboard: 1 },
      params: {},
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);

     if(config.enable_metabase)
     {
      this.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true";
     }
    console.log("analutic url has been set",this.iframeUrl);
  }
};
</script>
